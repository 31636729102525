<template lang="pug">
  tr.table__row.table__row--body
    td.table__cell {{ ingredient.name }}
    td.table__cell {{ ingredient.weight }}
    td.table__cell {{ ingredient.remainder_day }}
    td.table__cell {{ ingredient.remainder_weight }}
    td.table__cell
      .table__cell-wrapper
        p.table__cell-text {{ ingredient.cost }}
        v-btn(
          icon
          v-permission-disable="permissions.update_ingredient"
          @click="modals.showSettings = true").table__button
          icon-gear.table__settings
        v-dialog(
          max-width="348"
          v-model="modals.showSettings")
          settings-modal(
            :item="ingredient"
            @close="modals.showSettings = false")
        qr-code-dropdown(
          position-right
          :name="ingredient.name"
          :url="ingredient.qr_code")
</template>

<script>
import IconGear from '@/components/icons/IconGear'
import QrCodeDropdown from '@/components/common/qr-code-dropdown/QrCodeDropdown'
import SettingsModal from './modals/SettingsModal'
import permissions from '@/util/permissions'

export default {
  name: 'IngredientsTableRow',

  props: {
    days: String,
    ingredient: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    IconGear,
    QrCodeDropdown,
    SettingsModal
  },

  data: () => ({
    modals: {
      showQrCode: false,
      showSettings: false
    },
    permissions: permissions
  })
}
</script>

<style lang="scss" scoped>
.table__cell {
  max-width: 16rem;
}
</style>
